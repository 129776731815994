// import React, { useEffect, useState } from "react";
// import { db } from "../firebase"; // Import the Firebase configuration
// import { collection, getDocs } from "firebase/firestore"; import "./Styles/Exterior.css";
// import paint from "./Images/Apex Floor Guard.png";
// import Image2 from "./Images/New/22.svg";
// import Image3 from "./Images/New/32.png";
// import Image4 from "./Images/New/33.svg";
// import Image5 from "./Images/New/34.svg";
// import Image6 from "./Images/New/35.png";
// import Image7 from "./Images/New/36.png";
// import Image8 from "./Images/New/37.png";


// const Exterior = () => {
//   const [products, setProducts] = useState([]);

//   // Fetch product data from Firebase
//   useEffect(() => {
//     const fetchProducts = async () => {
//       const querySnapshot = await getDocs(collection(db, "exterior"));
//       // Assuming collection name is 'waterproofing'
//       const productList = querySnapshot.docs.map((doc) => doc.data());
//       console.log(productList);
//       setProducts(productList);
//     };

//     fetchProducts();
//   }, []);
//   return (
//     <div>
//       <div className="exterior-section">
//         <h3
//           className="exterior-section__title"
//           data-aos="fade-up"
//           data-aos-duration="1500"
//         >
//           Exterior
//         </h3>
//         <div
//           className="exterior-section__list"
//           data-aos="fade-up"
//           data-aos-duration="1500"
//         >
//           <div className="product-card_ex">
//             <div className="ex_Product_bg">
//               <img
//                 className="product-card_ex__image"
//                 src={Image2}
//                 alt="Apex Floor Guard"
//               />
//             </div>

//             <h4 className="product-card_ex__name">ACE Exterior Emulsion</h4>

//             <div className="product-card_ex__rating">⭐⭐⭐⭐☆</div>
//             <p className="product-card_ex__description">
//               Choose Ace Exterior Emulsion for quick, affordable, weather-resistant outdoor protection.
//             </p>
//           </div>
//           <div className="product-card_ex">
//             <div className="ex_Product_bg">
//               <img
//                 className="product-card_ex__image"
//                 src={Image3}
//                 alt="Apex Floor Guard"
//               />
//             </div>
//             <h4 className="product-card_ex__name">Ace Advanced</h4>
//             <div className="product-card_ex__rating">⭐⭐⭐⭐☆</div>
//             <p className="product-card_ex__description">
//               Achieve your dream finish with this weather-resistant, lead-free paint, available in over 1600 shades to beautify and protect your home.
//             </p>
//           </div>
//           <div className="product-card_ex">
//             <div className="ex_Product_bg">
//               <img
//                 className="product-card_ex__image"
//                 src={Image7}
//                 alt="Apex Floor Guard"
//               />
//             </div>
//             <h4 className="product-card_ex__name">Apex</h4>
//             <div className="product-card_ex__rating">⭐⭐⭐⭐☆</div>
//             <p className="product-card_ex__description">
//               Smooth, water-based finish with dust resistance, 5-year warranty, and weather protection.
//             </p>
//           </div>
//           <div className="product-card_ex">
//             <div className="ex_Product_bg">
//               <img
//                 className="product-card_ex__image"
//                 src={Image5}
//                 alt="Apex Floor Guard"
//               />
//             </div>
//             <h4 className="product-card_ex__name">Apex advanced</h4>
//             <div className="product-card_ex__rating">⭐⭐⭐⭐☆</div>
//             <p className="product-card_ex__description">
//               Ensure weatherproofing with this dust-proof, anti-algal acrylic wall finish.
//             </p>
//           </div>
//           <div className="product-card_ex">
//             <div className="ex_Product_bg">
//               <img
//                 className="product-card_ex__image"
//                 src={Image8}
//                 alt="Apex Floor Guard"
//               />
//             </div>
//             <h4 className="product-card_ex__name">Apex Floor Guard</h4>
//             <div className="product-card_ex__rating">⭐⭐⭐⭐⭐</div>
//             <p className="product-card_ex__description">
//               Apex Floor Guard is durable, high-resistance acrylic emulsion for tailored applications.
//             </p>
//           </div>
//           <div className="product-card_ex">
//             <div className="ex_Product_bg">
//               <img
//                 className="product-card_ex__image"
//                 src={Image4}
//                 alt="Apex Floor Guard"
//               />
//             </div>
//             <h4 className="product-card_ex__name">Apex Ultima</h4>
//             <div className="product-card_ex__rating">⭐⭐⭐⭐☆</div>
//             <p className="product-card_ex__description">
//               High-performance exterior paint with DPUR, resists dust, algae, and fungus.
//             </p>
//           </div>
//           <div className="product-card_ex">
//             <div className="ex_Product_bg">
//               <img
//                 className="product-card_ex__image"
//                 src={Image6}
//                 alt="Apex Floor Guard"
//               />
//             </div>
//             <h4 className="product-card_ex__name">Dura life top coat</h4>
//             <div className="product-card_ex__rating">⭐⭐⭐⭐☆</div>
//             <p className="product-card_ex__description">
//               Ultra-durable nanotech paint resists dampness, cracks, and fading, ensuring longevity.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Exterior;


import React, { useEffect, useState } from "react";
import { db } from "../firebase"; // Import the Firebase configuration
import { collection, getDocs } from "firebase/firestore";
import "./Styles/Exterior.css";

const Exterior = () => {
  const [products, setProducts] = useState([]);

  // Fetch product data from Firebase
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "exterior"));
        const productList = querySnapshot.docs.map((doc) => doc.data());
        setProducts(productList);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div>
      <div className="exterior-section">
        <h3
          className="exterior-section__title"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Exterior
        </h3>
        <div
          className="exterior-section__list"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          {products.map((product, index) => (
            <div className="product-card_ex" key={index}>
              <div className="ex_Product_bg">
                <img
                  className="product-card_ex__image"
                  src={product.imageUrl}
                  alt={product.name}
                />
              </div>
              <h4 className="product-card_ex__name">{product.name}</h4>
              <div className="product-card_ex__rating">⭐⭐⭐⭐☆</div>
              <p className="product-card_ex__description">{product.subtitle}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Exterior;

