// import React, { useEffect, useState } from "react";
// import { db } from "../firebase"; // Import the Firebase configuration
// import { collection, getDocs } from "firebase/firestore";import "./Styles/Interior.css";
// import paint from "./Images/Apex Floor Guard.png";
// import Image1 from "./Images/New/41.png";
// import Image2 from "./Images/New/42.png";

// import Image3 from "./Images/New/43.png";

// import Image4 from "./Images/New/44.png";

// import Image5 from "./Images/New/45.png";

// import Image6 from "./Images/New/46.png";


// const Interior = () => {
//    const [products, setProducts] = useState([]);

//     // Fetch product data from Firebase
//     useEffect(() => {
//       const fetchProducts = async () => {
//         try {
//           const querySnapshot = await getDocs(collection(db, "interior"));
//           const productList = querySnapshot.docs.map((doc) => doc.data());
//           setProducts(productList);
//         } catch (error) {
//           console.error("Error fetching products: ", error);
//         }
//       };

//       fetchProducts();
//     }, []);
//   return (
//     <div className="interior-section">
//       <h3
//         className="interior-section__title"
//         data-aos="fade-up"
//         data-aos-duration="1500"
//       >
//         Interior
//       </h3>
//       <div
//         className="interior-section__list"
//         data-aos="fade-up"
//         data-aos-duration="1500"
//       >
//         <div className="product-card_in">
//           <div className="ex_Product_bg">
//             <img
//               className="product-card_ex__image"
//               src={Image1}
//               alt="Apex Floor Guard"
//             />
//           </div>
//           {/* <img className="product-card_in__image" src={paint}alt="Apex Floor Guard" /> */}
//           <h4 className="product-card_in__name">Royale Glitz</h4>
//           <div className="product-card_in__rating">⭐⭐⭐⭐☆</div>
//           <p className="product-card_in__description">
//             Ultra-sheen interior paint offers luxurious finish and stain repellency.
//           </p>
//         </div>
//         <div className="product-card_in">
//           <div className="ex_Product_bg">
//             <img
//               className="product-card_ex__image"
//               src={Image2}
//               alt="Apex Floor Guard"
//             />
//           </div>
//           <h4 className="product-card_in__name">Royale Luxury Emulsion</h4>
//           <div className="product-card_in__rating">⭐⭐⭐⭐☆</div>
//           <p className="product-card_in__description">
//             Luxury paint with Teflon protector is washable, durable, and non-toxic.
//           </p>
//         </div>
//         <div className="product-card_in">
//           <div className="ex_Product_bg">
//             <img
//               className="product-card_ex__image"
//               src={Image3}
//               alt="Apex Floor Guard"
//             />
//           </div>
//           <h4 className="product-card_in__name">Royale Shyne</h4>
//           <div className="product-card_in__rating">⭐⭐⭐⭐☆</div>
//           <p className="product-card_in__description">
//             High-sheen, washable paint with Teflon protection ensures long-lasting beauty.
//           </p>
//         </div>
//         <div className="product-card_in">
//           <div className="ex_Product_bg">
//             <img
//               className="product-card_ex__image"
//               src={Image4}
//               alt="Apex Floor Guard"
//             />
//           </div>
//           <h4 className="product-card_in__name">Royale Matt</h4>
//           <div className="product-card_in__rating">⭐⭐⭐⭐☆</div>
//           <p className="product-card_in__description">
//             Washable matte paint with Teflon protection resists tough stains and retains beauty.
//           </p>
//         </div>
//         <div className="product-card_in">
//           <div className="ex_Product_bg">
//             <img
//               className="product-card_ex__image"
//               src={Image5}
//               alt="Apex Floor Guard"
//             />
//           </div>
//           <h4 className="product-card_in__name">Apcolite Advanced Shyne</h4>
//           <div className="product-card_in__rating">⭐⭐⭐⭐⭐</div>
//           <p className="product-card_in__description">
//             Washable paint with stain guard protects walls from fungus and moisture.
//           </p>
//         </div>
//         <div className="product-card_in">
//           <div className="ex_Product_bg">
//             <img
//               className="product-card_ex__image"
//               src={Image6}
//               alt="Apex Floor Guard"
//             />
//           </div>
//           <h4 className="product-card_in__name">Tractor Emulsion Advanced</h4>
//           <div className="product-card_in__rating">⭐⭐⭐⭐☆</div>
//           <p className="product-card_in__description">
//             Smooth-finish paint with anti-fungal shield, available in various shades.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Interior;

import React, { useEffect, useState } from "react";
import { db } from "../firebase"; // Import the Firebase configuration
import { collection, getDocs } from "firebase/firestore";
import "./Styles/Interior.css";

const Interior = () => {
  const [products, setProducts] = useState([]);

  // Fetch product data from Firebase
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "interior"));
        const productList = querySnapshot.docs.map((doc) => doc.data());
        setProducts(productList);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="interior-section">
      <h3
        className="interior-section__title"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        Interior
      </h3>
      <div
        className="interior-section__list"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        {products.map((product, index) => (
          <div className="product-card_in" key={index}>
            <div className="ex_Product_bg">
              <img
                className="product-card_ex__image"
                src={product.imageUrl}
                alt={product.name}
              />
            </div>
            <h4 className="product-card_in__name">{product.name}</h4>
            <div className="product-card_in__rating">⭐⭐⭐⭐☆</div>
            <p className="product-card_in__description">{product.subtitle}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Interior;

