// import React, { useState ,useEffect} from "react";
// import "./Gallery.css";
// import wallpaper1 from "./Images/Wall/1.jpeg";
// import wallpaper2 from "./Images/Wall/2.jpeg";
// import wallpaper3 from "./Images/Wall/7.jpeg";
// import wallpaper4 from "./Images/Wall/4.jpeg";
// import wallpaper5 from "./Images/Wall/5.jpeg";
// import wallpaper6 from "./Images/Wall/6.jpeg";
// import painting1 from "./Images/paint/1.jpeg";
// import painting2 from "./Images/paint/2.jpeg";

// import painting3 from "./Images/paint/3.jpeg";

// import painting4 from "./Images/paint/4.jpeg";

// import painting5 from "./Images/paint/5.jpeg";

// import painting6 from "./Images/paint/6.jpeg";

// import royal1 from "./Images/royal/1.jpeg";
// import royal2 from "./Images/royal/2.jpeg";

// import royal3 from "./Images/royal/3.jpeg";

// import royal4 from "./Images/royal/4.jpeg";

// import royal5 from "./Images/royal/5.jpeg";

// import royal6 from "./Images/royal/6.jpeg";

// import stencil1 from "./Images/stencil/1.jpeg";
// import stencil2 from "./Images/stencil/2.jpeg";
// import stencil3 from "./Images/stencil/3.jpeg";
// import stencil4 from "./Images/stencil/4.jpeg";
// import texture1 from "./Images/texture/1.jpeg";
// import texture2 from "./Images/texture/2.jpeg";

// import texture3 from "./Images/texture/3.jpeg";

// import Celling1 from "./Images/celling/1.jpeg";
// import Celling2 from "./Images/celling/2.jpeg";
// import Celling3 from "./Images/celling/3.jpeg";
// import Celling4 from "./Images/celling/4.jpeg";
// import Celling5 from "./Images/celling/5.jpeg";
// import Celling6 from "./Images/celling/6.jpeg";

// import Design1 from "./Images/design/1.jpeg";
// import Design2 from "./Images/design/2.jpeg";

// import Design3 from "./Images/design/3.jpeg";

// import Design4 from "./Images/design/4.jpeg";
// import { db } from "../firebase"; // Adjust this import based on your Firebase config fileimport { collection, getDocs } from "firebase/firestore";
// import { collection, getDocs } from "firebase/firestore";



// // Image data for each section
// const sections = {
//     Wallpaper: [wallpaper1, wallpaper2, wallpaper3, wallpaper4, wallpaper5, wallpaper6],
//     "Painting House": [painting1, painting2, painting3, painting4, painting5, painting6],
//     "Royal Play": [royal1, royal2, royal3, royal4, royal5, royal6],
//     Stencil: [stencil1, stencil2, stencil3, stencil4],
//     Texture: [texture1, texture2, texture3],
//     Celling: [Celling1, Celling2, Celling3, Celling4, Celling5, Celling6],
//     Design:[Design1,Design2,Design3,Design4]
// };

// const Gallery = () => {
//     const [activeSection, setActiveSection] = useState("Wallpaper");
//     const [galleryImages, setGalleryImages] = useState([]);

//     useEffect(() => {
//       const fetchGalleryImages = async () => {
//         try {
//           const querySnapshot = await getDocs(collection(db, "paintGallery"));
//           // Each document contains an 'images' array
//           const images = querySnapshot.docs.flatMap((doc) => doc.data().images);
//           console.log(images); // Confirm the structure in the console
//           setGalleryImages(images);
//         } catch (error) {
//           console.error("Error fetching gallery images: ", error);
//         }
//       };

//       fetchGalleryImages();
//     }, []);

//     return (
//         <div className="gallery-container">
//             {/* Switch Buttons */}
//             <div className="gallery-buttons">
//                 {Object.keys(sections).map((section) => (
//                     <button
//                         key={section}
//                         className={`gallery-button ${activeSection === section ? "active" : ""}`}
//                         onClick={() => setActiveSection(section)}
//                     >
//                         {section}
//                     </button>
//                 ))}
//             </div>

//             {/* Image Gallery */}
//             <div className="gallery-grid">
//                 {sections[activeSection].map((image, index) => (
//                     <div key={index} className="gallery-item">
//                         <img src={image} alt={`${activeSection} ${index + 1}`} />
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default Gallery;

import React, { useState, useEffect } from "react";
import "./Gallery.css";
import { db } from "../firebase"; // Adjust this import based on your Firebase config file
import { collection, getDocs } from "firebase/firestore";

// Image data for each section (initial data can be kept here as fallback)
const sections = {
    wallpaper: [],
    paintingHouse: [],
    RoyalPlay: [],
    Stencil: [],
    Texture: [],
    Celling: [],
    Design: [],
    Documentation: [],
};

const Gallery = () => {
    const [activeSection, setActiveSection] = useState("wallpaper");
    const [galleryImages, setGalleryImages] = useState([]);

    useEffect(() => {
        const fetchGalleryImages = async (section) => {
            try {
                // Fetch images for the selected section from Firestore
                const querySnapshot = await getDocs(collection(db, section));
                // Each document contains an 'images' array
                const images = querySnapshot.docs.flatMap((doc) => doc.data().images);
                console.log(`Fetched ${section} images:`, images);
                setGalleryImages(images);
            } catch (error) {
                console.error("Error fetching gallery images: ", error);
            }
        };

        // Fetch images for the active section
        fetchGalleryImages(activeSection);

    }, [activeSection]); // Depend on activeSection to fetch images for that section

    return (
        <div className="gallery-container">
            {/* Switch Buttons */}
            <div className="gallery-buttons">
                {Object.keys(sections).map((section) => (
                    <button
                        key={section}
                        className={`gallery-button ${activeSection === section ? "active" : ""}`}
                        onClick={() => setActiveSection(section)}
                    >
                        {section}
                    </button>
                ))}
            </div>

            {/* Image Gallery */}
            <div className="gallery-grid">
                {galleryImages.map((image, index) => (
                    <div key={index} className="gallery-item">
                        <img src={image.url} alt={`${activeSection} ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
