import React from "react";
import "./Styles/HeroSection.css";
import Banner from "./Images/banner.png";
import rec from "./Images/Rectangle 10.png";
import wood from "./Images/wood.png";
import wood1 from "./Images/wood2.png";
import wood3 from "./Images/wood3.png";
import Main from "./Images/1.jpeg";

const HeroSection = () => {
  return (
    <div className="play_wood_hero_section">
      <img src={wood} alt="wood" className="wood" />
      <img src={wood1} alt="wood2" className="wood2" />
      <img src={wood3} alt="wood2" className="wood3" />

      <h2 className="innovation__title" data-aos="fade-up" data-aos-duration="1500">
        Quality Meets Innovation
      </h2>
      <p className="innovation__subtitle" data-aos="fade-up" data-aos-duration="1500">
        Our plywood section, introduced in 2019, has quickly become a trusted
        source for premium plywood products and interior design essentials.
      </p>

      <div className="innovation" data-aos="fade-up" data-aos-duration="1500">
        <div className="innovation__content">
          <div className="banner1">
            {" "}
            <img src={rec} alt="Plywood" className="innovation__image" />
            <img
              src={Main}
              alt="Plywood"
              className="innovation__image Img2nd"
            />
          </div>
        </div>
        <div className="innovation__quote-form">
          <form className="innovation__form">
            <h3 className="innovation__form-title">Request Free Quote</h3>
            <input
              type="text"
              placeholder="Name"
              className="innovation__input"
            />
            <input
              type="email"
              placeholder="Email Id"
              className="innovation__input"
            />
            <input
              type="tel"
              placeholder="Phone no"
              className="innovation__input"
            />
            <input
              type="text"
              placeholder="Content"
              className="innovation__input"
            />
            {/* <textarea placeholder="Content" className="innovation__textarea"></textarea> */}
            <button type="submit" className="innovation__submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
