
// import React, { useEffect, useState } from "react";
// import { db } from "../firebase"; // Import the Firebase configuration
// import { collection, getDocs } from "firebase/firestore";
// import "./Styles/WoodFinish.css";
// import touch from "./Images/Touch wood.png";
// import wood1 from "./Images/202916924.png";
// import wood2 from "./Images/231.png";
// import wood3 from "./Images/202916925.png";
// import wood4 from "./Images/236.png";
// import es from "./Images/Esdee Woodfiller.png";
// const WoodFinishesSection = () => {
//     const [products, setProducts] = useState([]);

//       useEffect(() => {
//         const fetchProducts = async () => {
//           const querySnapshot = await getDocs(collection(db, "woodFinesh"));
//           // Assuming collection name is 'waterproofing'
//           const productList = querySnapshot.docs.map((doc) => doc.data());
//           console.log(productList);
//           setProducts(productList);
//         };

//         fetchProducts();
//       }, []);

//   return (
//     <div className="bgGray">
//       <div className="wood-finishes-section">
//         <h3
//           className="wood-finishes-section__title"
//           data-aos="zoom-in"
//           data-aos-duration="1500"
//         >
//           Wood Finishes
//         </h3>
//         <div
//           className="wood-finishes-section__list"
//           data-aos="zoom-in"
//           data-aos-duration="1500"
//         >
//           <div className="wood-card_finish">
//             <div className="wood_card_text_conatainer">
//               {" "}
//               <h4 className="wood-card_finish__name">Touch wood</h4>
//               <div className="wood-card_finish__rating">⭐⭐⭐⭐⭐</div>
//               <p className="wood-card_finish__description">
//                 Effortless, hard finish; no sealer needed, economical as
//                 undercoat and topcoat.
//               </p>
//               <div className="wood-card_finish__sample">
//                 <img
//                   className="wood-card_finish__sample-image"
//                   src={wood1}
//                   alt="Wood Sample 1"
//                 />
//                 <img
//                   className="wood-card_finish__sample-image"
//                   src={wood2}
//                   alt="Wood Sample 2"
//                 />
//               </div>
//             </div>
//             <div className="bg-lightCss">
//               {" "}
//               <img
//                 className="wood-card_finish__image"
//                 src={touch}
//                 alt="Touch Wood"
//               />
//             </div>
//           </div>
//           <div className="wood-card_finish">
//             <div className="wood_card_text_conatainer">
//               <h4 className="wood-card_finish__name">Esdee Woodfiller</h4>
//               <div className="wood-card_finish__rating">⭐⭐⭐⭐⭐</div>
//               <p className="wood-card_finish__description">
//                 Wood filler repairs holes in wood, smoothing even minor grain
//                 defects.
//               </p>
//               <div className="wood-card_finish__sample">
//                 <img
//                   className="wood-card_finish__sample-image"
//                   src={wood3}
//                   alt="Wood Sample 1"
//                 />
//                 <img
//                   className="wood-card_finish__sample-image"
//                   src={wood4}
//                   alt="Wood Sample 2"
//                 />
//               </div>
//             </div>
//             <div className="bg-lightCss">
//               {" "}
//               <img
//                 className="wood-card_finish__image"
//                 src={es}
//                 alt="Esdee Woodfiller"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WoodFinishesSection;


import React, { useEffect, useState } from "react";
import { db } from "../firebase"; // Import the Firebase configuration
import { collection, getDocs } from "firebase/firestore";
import "./Styles/WoodFinish.css";
import wood1 from "./Images/202916924.png";
import wood2 from "./Images/231.png";
import wood3 from "./Images/202916925.png";
import wood4 from "./Images/236.png";

const WoodFinishesSection = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "woodFinesh"));
      const productList = querySnapshot.docs.map((doc) => doc.data());
      setProducts(productList);
    };

    fetchProducts();
  }, []);

  return (
    <div className="bgGray">
      <div className="wood-finishes-section">
        <h3
          className="wood-finishes-section__title"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          Wood Finishes
        </h3>
        <div
          className="wood-finishes-section__list"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          {products.map((product, index) => (
            <div className="wood-card_finish" key={index}>
              <div className="wood_card_text_conatainer">
                <h4 className="wood-card_finish__name">{product.name}</h4>
                <div className="wood-card_finish__rating">⭐⭐⭐⭐⭐</div>
                <p className="wood-card_finish__description">
                  {product.subtitle}
                </p>
                <div className="wood-card_finish__sample">
                  {index === 0 ? (
                    <>
                      <img
                        className="wood-card_finish__sample-image"
                        src={wood1}
                        alt="Wood Sample 1"
                      />
                      <img
                        className="wood-card_finish__sample-image"
                        src={wood2}
                        alt="Wood Sample 2"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className="wood-card_finish__sample-image"
                        src={wood3}
                        alt="Wood Sample 1"
                      />
                      <img
                        className="wood-card_finish__sample-image"
                        src={wood4}
                        alt="Wood Sample 2"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="bg-lightCss">
                <img
                  className="wood-card_finish__image"
                  src={product.imageUrl}
                  alt={product.name}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WoodFinishesSection;
