// import React from "react";
// import "./Styles/InteriorSolutions.css";
// import product from "./Images/product.png";
// import Image1 from "./Images/New/1.png";
// import Image2 from "./Images/New/2.png";

// import Image3 from "./Images/lock2.jpeg";

// import Image4 from "./Images/New/5.png";

// import Image5 from "./Images/New/3.png";

// import Image6 from "./Images/screw.jpeg";

// import Image7 from "./Images/handle.jpeg";
// import Image8 from "./Images/lock.jpeg";

// const ProductCard = ({ image, title, description, rating }) => {
//   return (
//     <div className="product-card">
//       <img src={image} alt={title} className="product-card__image" />
//       <h3 className="product-card__title">{title}</h3>
//       <div className="product-card__rating">
//         {"★".repeat(rating)}
//         {"☆".repeat(5 - rating)}
//       </div>
//       <p className="product-card__description">{description}</p>
//     </div>
//   );
// };

// const InteriorSolutions = () => {
//   // const products = [
//   //   {
//   //     image: Image1,
//   //     title: "Vitalia Neo",
//   //     description:
//   //       "Lorem ipsum dolor sit amet consectetur. Eu fringilla risus gravida quis.",
//   //     rating: 4,
//   //   },
//   //   {
//   //     image: Image2,
//   //     title: "Vitalia Neo",
//   //     description:
//   //       "Lorem ipsum dolor sit amet consectetur. Eu fringilla risus gravida quis.",
//   //     rating: 5,
//   //   },
//   //   {
//   //     image: Image3,
//   //     title: "Vitalia Neo",
//   //     description:
//   //       "Lorem ipsum dolor sit amet consectetur. Eu fringilla risus gravida quis.",
//   //     rating: 3,
//   //   },
//   //   {
//   //     image: Image4,
//   //     title: "Vitalia Neo",
//   //     description:
//   //       "Lorem ipsum dolor sit amet consectetur. Eu fringilla risus gravida quis.",
//   //     rating: 4,
//   //   },
//   //   {
//   //     image: Image5,
//   //     title: "Vitalia Neo",
//   //     description:
//   //       "Lorem ipsum dolor sit amet consectetur. Eu fringilla risus gravida quis.",
//   //     rating: 5,
//   //   },
//   //   {
//   //     image: Image6,
//   //     title: "Vitalia Neo",
//   //     description:
//   //       "Lorem ipsum dolor sit amet consectetur. Eu fringilla risus gravida quis.",
//   //     rating: 3,
//   //   },
//   //   {
//   //     image: Image7,
//   //     title: "Vitalia Neo",
//   //     description:
//   //       "Lorem ipsum dolor sit amet consectetur. Eu fringilla risus gravida quis.",
//   //     rating: 5,
//   //   },
//   //   {
//   //     image: Image8,
//   //     title: "Vitalia Neo",
//   //     description:
//   //       "Lorem ipsum dolor sit amet consectetur. Eu fringilla risus gravida quis.",
//   //     rating: 3,
//   //   },
//   //   // Add more product objects as needed
//   // ];
//   const products = [
//     {
//       image: Image1,
//       title: "Dead Bolt Locks",
//       description:
//         "Our shop offers a wide variety of Godrej door locks for every need.",
//       rating: 4,
//     },
//     {
//       image: Image2,
//       title: "Pad locks",
//       description:
//         "Explore our diverse padlocks in stainless steel and brass materials.",
//       rating: 5,
//     },
//     {
//       image: Image3,
//       title: "Mortise Lock",
//       description:
//         "Discover modern and antique mortise locks to suit every style.",
//       rating: 3,
//     },
//     {
//       image: Image4,
//       title: "Ladders",
//       description:
//         "We offer versatile ladders for household tasks and professional work.",
//       rating: 4,
//     },
//     {
//       image: Image5,
//       title: "Bolts",
//       description:
//         "Find bolts in all sizes and materials to meet your needs.",
//       rating: 5,
//     },
//     {
//       image: Image6,
//       title: "Screws",
//       description:
//         "Explore a wide range of screws available in precise sizes.",
//       rating: 4,
//     },
//     {
//       image: Image7,
//       title: "Handles",
//       description:
//         "Browse our wide collection of high-quality handles for every need.",
//       rating: 5,
//     },
//     {
//       image: Image8,
//       title: "Hinges",
//       description:
//         "Discover high-quality hinges that ensure smooth operation and durability.",
//       rating: 3,
//     },
//     // Add more product objects as needed
//   ];

//   return (
//     <div className="interior-solutions">
//       <h2
//         className="interior-solutions__title"
//         data-aos="fade-up"
//         data-aos-duration="1500"
//       >
//         Complete{" "}
//         <span className="interior-solutions__highlight">
//           Interior Solutions
//         </span>
//       </h2>
//       <div
//         className="interior-solutions__grid"
//         data-aos="fade-up"
//         data-aos-duration="1500"
//       >
//         {products.map((product, index) => (
//           <ProductCard
//             key={index}
//             image={product.image}
//             title={product.title}
//             description={product.description}
//             rating={product.rating}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default InteriorSolutions;


import React, { useEffect, useState } from "react";
import { db } from "../firebase"; // Import your Firebase configuration
import { collection, getDocs } from "firebase/firestore";
import "./Styles/InteriorSolutions.css";

const ProductCard = ({ image, title, description, rating }) => {
  return (
    <div className="product-card">
      <img src={image} alt={title} className="product-card__image" />
      <h3 className="product-card__title">{title}</h3>
      <div className="product-card__rating">
        {"★".repeat(rating)}
        {"☆".repeat(5 - rating)}
      </div>
      <p className="product-card__description">{description}</p>
    </div>
  );
};

const InteriorSolutions = () => {
  const [products, setProducts] = useState([]);

  // Fetch hardware products from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "hardware"));
        const productList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productList);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="interior-solutions">
      <h2
        className="interior-solutions__title"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        Complete{" "}
        <span className="interior-solutions__highlight">
          Interior Solutions
        </span>
      </h2>
      <div
        className="interior-solutions__grid"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        {products.map((product) => (
          <ProductCard
            key={product.id}
            image={product.imageUrl}
            title={product.name}
            description={product.subtitle
            }
            rating={5}
          />
        ))}
      </div>
    </div>
  );
};

export default InteriorSolutions;
